import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  useMediaQuery,
} from "@mui/material";

const CardComponent = ({ image, title, description, backgroundColor }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <Card
      sx={{
        height: isMobile ? "460px" : "500px",
        width: isMobile ? "330px" : "400px",
        backgroundColor: backgroundColor,
        borderRadius: 5,
        textAlign: "start",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        margin: "20px auto",
        overflow: "hidden",
      }}
    >
      <CardContent sx={{ padding: isMobile ? "16px" : "24px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            overflow: "hidden",
            marginBottom: isMobile ? "16px" : "24px",
          }}
        >
          <img
            src={image}
            alt={title}
            style={{
              height: isMobile ? "100%" : "304px",
              objectFit: "cover",
            }}
          />
        </Box>
        <Box sx={{ padding: isMobile ? "0 16px" : "0 24px" }}>
          <Typography variant="h6" component="div" sx={{ fontWeight: 700 }}>
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ marginTop: "8px" }}
          >
            {description}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardComponent;
