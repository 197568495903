import React from "react";
import "./PricingPlans.css";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

const PricingPlans = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <Box
      id="Tariffs"
      className="pricing-plans"
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: isMobile ? "600px" : "0",
      }}
    >
      <Typography
        sx={{
          marginBottom: "50px",
          fontWeight: 700,
          fontSize: "40px",
          marginTop: "200px",
        }}
      >
        Тарифы
      </Typography>
      <Box
        className="plans"
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: isMobile ? 8 : 2,
        }}
      >
        <Box
          className="plan basic"
          sx={{ borderColor: "rgba(20, 20, 20, 1)", paddingBottom: "150px" }}
        >
          <Typography
            variant="h6"
            sx={{
              marginBottom: "25px",
              fontWeight: 600,
            }}
          >
            Базовый
          </Typography>
          <Typography
            variant="h4"
            sx={{ marginBottom: "20px", fontWeight: 600 }}
          >
            $0
          </Typography>
          <Button
            sx={{
              marginBottom: "15px",
              fontWeight: 500,
              backgroundColor: "rgba(69, 167, 245, 1) !important",
            }}
            fullWidth
            variant="contained"
          >
            Начать
          </Button>
          <ul>
            <li>
              <CheckIcon />
              20 сообщений в день
            </li>
            <li>
              <CheckIcon />
              Базовые настройки
            </li>
            <li>
              <CheckIcon />
              Поддержка по почте
            </li>
          </ul>
        </Box>
        <Box
          className="plan advanced"
          sx={{
            borderColor: "none",
            backgroundColor: "rgba(69, 167, 245, 1)",
            color: "white",
            paddingBottom: "100px",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              marginBottom: "25px",
              fontWeight: 600,
            }}
          >
            Продвинутый
          </Typography>
          <Typography
            variant="h4"
            sx={{
              marginBottom: "20px",
              fontWeight: 600,
            }}
          >
            $6,99/в месяц
          </Typography>
          <Button
            fullWidth
            variant="contained"
            sx={{
              backgroundColor: "white !important",
              color: "rgba(69, 167, 245, 1) !important",
              marginBottom: "15px",
            }}
          >
            Начать
          </Button>
          <ul>
            <li>
              <CheckIcon />
              50 сообщений в день
            </li>
            <li>
              <CheckIcon />
              Персонализированные настройки
            </li>
            <li>
              <CheckIcon />
              24/7 Поддержка
            </li>
            <li>
              <CheckIcon />
              Бот настроен индивидуально под вас
            </li>
          </ul>
        </Box>
        <Box
          className="plan premium"
          sx={{ borderColor: "rgba(20, 20, 20, 1)" }}
        >
          <Typography
            variant="h6"
            sx={{
              marginBottom: "25px",
              fontWeight: 600,
            }}
          >
            Осознанный
          </Typography>
          <Typography
            variant="h4"
            sx={{
              marginBottom: "20px",
              fontWeight: 600,
            }}
          >
            $14,99/в месяц
          </Typography>
          <Button
            sx={{
              marginBottom: "15px",
              fontWeight: 500,
              backgroundColor: "rgba(69, 167, 245, 1) !important",
            }}
            fullWidth
            variant="contained"
          >
            Начать
          </Button>
          <ul>
            <li>
              <CheckIcon />
              Безлимитная доступность
            </li>
            <li>
              <CheckIcon />
              Персонализированные настройки
            </li>
            <li>
              <CheckIcon />
              Уникальные упражнения
            </li>
            <li>
              <CheckIcon />
              Отслеживание прогресса
            </li>
            <li>
              <CheckIcon />
              24/7 Поддержка
            </li>
            <li>
              <CheckIcon />
              Бот настроен индивидуально под вас
            </li>
          </ul>
        </Box>
      </Box>
    </Box>
  );
};

export default PricingPlans;
