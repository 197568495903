import React, { useEffect, useState } from "react";
import htmlReactParser from "html-react-parser";
import privacyPolicyText from "./PrivacyPolicy.txt";
import "./styles.css"; // Импортируйте ваш CSS файл

const PrivacyPolicy = () => {
  const [text, setText] = useState("");

  useEffect(() => {
    fetch(privacyPolicyText)
      .then((response) => response.text())
      .then((htmlText) => {
        // Здесь мы больше не удаляем теги, а просто загружаем текст как есть
        setText(htmlText);
      });
  }, []);

  return (
    <div className="privacy-policy">
      <div>{htmlReactParser(text)}</div>
    </div>
  );
};

export default PrivacyPolicy;
