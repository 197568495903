import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation } from "react-router-dom";

const CustomAppBar = ({ redirectToHome }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();
  const location = useLocation();
  const sections = ["AboutApp", "HowCanHelp", "advantages", "contacts"];
  const pages = ["О МИЕ", "ТАРИФЫ", "О НАС", "ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ"];

  const handleDrawerToggle = (open) => () => {
    setDrawerOpen(open);
  };

  const handleNavigation = (path) => () => {
    navigate(path);
    setDrawerOpen(false);
  };

  const handlePageClick = (page) => {
    let sectionId;

    if (page === "ТАРИФЫ") {
      sectionId = "Tariffs";
    } else if (page === "О НАС") {
      sectionId = "AboutUs";
    } else if (page === "ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ") {
      navigate("/privacy-policy");
      return;
    } else if (page === "Чат с Мией") {
      sectionId = "chatbot";
    } else {
      sectionId = "Mia";
    }

    if (location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        document
          .getElementById(sectionId)
          ?.scrollIntoView({ behavior: "smooth" });
      }, 300);
    } else {
      document
        .getElementById(sectionId)
        ?.scrollIntoView({ behavior: "smooth" });
    }

    setDrawerOpen(false);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle(false)} sx={{ textAlign: "center" }}>
      <Box className="drawer-header">
        <IconButton
          onClick={handleDrawerToggle(false)}
          className="drawer-close"
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <List className="drawer-list">
        {pages.map((text) => (
          <ListItem button key={text} onClick={() => handlePageClick(text)}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
        <ListItem>
          <Button
            onClick={() => handlePageClick("Чат с Мией")}
            sx={{
              backgroundColor: "#00B0FF",
              borderRadius: "50px",
              padding: "14px 34px",
              gap: "10px",
              color: "white",
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "19.54px",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#008CBA",
              },
            }}
          >
            Чат с Мией
          </Button>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <AppBar
        position="static"
        sx={{ background: "none", boxShadow: "none", marginTop: "10px" }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" sx={{ my: 2 }}>
            MIA
          </Typography>
          <Box sx={{ display: { xs: "none", md: "flex" }, gap: 2 }}>
            {pages.map((text) => (
              <Button
                key={text}
                color="inherit"
                onClick={() => handlePageClick(text)}
              >
                {text}
              </Button>
            ))}
          </Box>

          <Button
            onClick={() => handlePageClick("Чат с Мией")}
            sx={{
              backgroundColor: "#00B0FF",
              borderRadius: "50px",
              padding: "14px 34px 14px 34px",
              gap: "10px",
              color: "white",
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "19.54px",
              textTransform: "none",
              display: { xs: "none", md: "inline-flex" },
              "&:hover": {
                backgroundColor: "#008CBA",
              },
            }}
          >
            Чат с Мией
          </Button>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle(true)}
            sx={{ display: { xs: "block", md: "none" } }}
          >
            <MenuIcon sx={{ color: "#00B0FF" }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="top"
        open={drawerOpen}
        onClose={handleDrawerToggle(false)}
        className="drawer"
      >
        {drawer}
      </Drawer>
    </>
  );
};

export default CustomAppBar;
