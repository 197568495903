import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import "./LifeImprovements.css";
import foto1 from "../../assets/foto1.png";
import foto2 from "../../assets/foto2.png";
import foto3 from "../../assets/foto3.png";
import foto4 from "../../assets/foto4.png";
import foto5 from "../../assets/foto5.png";

const LifeImprovements = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const improvements = [
    {
      title: "Улучшение психического здоровья",
      description:
        "Медитация и осознанность могут помочь уменьшить стресс и улучшить ваше психическое благополучие.",
      img: foto1,
    },
    {
      title: "Постоянная поддержка",
      description:
        "Ваш бот всегда доступен для помощи и поддержки, когда вам это нужно.",
      img: foto2,
    },
    {
      title: "Индивидуальный подход",
      description:
        "Получайте рекомендации и советы, адаптированные к вашим личным потребностям и целям.",
      img: foto3,
    },
    {
      title: "Повышенная эмоциональная устойчивость",
      description:
        "Узнайте, как справляться с трудностями и оставаться эмоционально устойчивым.",
      img: foto4,
    },
    {
      title: "Формирование здоровых привычек",
      description:
        "Создайте и поддерживайте здоровые привычки, которые улучшат ваше общее состояние.",
      img: foto5,
    },
  ];

  return (
    <Box
      className="life-improvements"
      sx={{
        textAlign: "center",
        backgroundColor: "white",
        padding: "20px",
        marginTop: "150px",
      }}
    >
      <Typography
        sx={{ fontWeight: 700, fontSize: "38px", marginBottom: "50px" }}
        gutterBottom
      >
        Как твоя жизнь может улучшиться
      </Typography>
      <Box
        sx={{
          backgroundColor: "rgba(69, 167, 245, 1)",
          paddingTop: "50px",
          paddingBottom: "20px",
        }}
      >
        {improvements.map((improvement, index) => (
          <Box
            key={index}
            className="improvement"
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: "center",
              marginBottom: "20px",
              backgroundColor: "rgba(69, 167, 245, 1) !important",
              padding: "10px",
              borderRadius: "8px",
              color: "white",
              textAlign: "start",
            }}
          >
            <Box
              sx={{
                flex: "1 1 50%",
                paddingRight: isMobile ? "0" : "10px",
                marginBottom: isMobile ? "10px" : "0",
                textAlign: "start",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: 500, fontSize: "32px" }}
              >
                {improvement.title}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "17px",
                  width: isMobile ? "100%" : "70%",
                  margin: isMobile ? "0 auto" : "0",
                }}
                variant="body1"
              >
                {improvement.description}
              </Typography>
            </Box>
            <Box
              sx={{ flex: "1 1 50%", textAlign: isMobile ? "start" : "end" }}
            >
              <img
                src={improvement.img}
                alt={improvement.title}
                style={{
                  width: "100%",
                  borderRadius: "8px",
                }}
              />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default LifeImprovements;
