import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import AboutUsImage from "../../assets/AboutUs.png";
import instagram from "../../assets/instagram.svg";
import gmail from "../../assets/gmail.svg";
import phone from "../../assets/phone.svg";

const AboutUs = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <Box
      id="AboutUs"
      height="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      overflow="hidden"
      marginTop={10}
    >
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: isMobile ? "32px" : "40px",
          textAlign: "center",
          marginBottom: "20px !important",
        }}
      >
        О нас
      </Typography>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundImage: `url(${AboutUsImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: isMobile ? "start" : "center",
          color: "white",
          padding: "20px",
          overflow: "hidden",
          marginLeft: isMobile ? "40px" : "0",
        }}
      >
        <Typography
          width={isMobile ? "90%" : "30%"}
          sx={{
            fontSize: isMobile ? "16px" : "18px",
            textAlign: isMobile ? "start" : "center",
          }}
        >
          Мы стремимся сделать психологическую поддержку доступной для всех. Мы
          верим, что забота о своем внутреннем мире — это ключ к счастью и
          здоровью. <br /> <br />
          Наше приложение “Mia" — это ваш личный карманный психолог, готовый
          помочь вам в любой момент.
        </Typography>
        <Box
          sx={{
            mt: 4,
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: isMobile ? "start " : "center",
            alignItems: isMobile ? "start" : "center",
            mb: 4,
            textAlign: isMobile ? "center" : "start",
          }}
        >
          <Box display={"flex"}>
            <Box
              component="img"
              src={instagram}
              alt="Instagram"
              sx={{
                width: 18,
                height: 18,
                mr: 1,
                mb: isMobile ? 1 : 0,
              }}
            />
            <Typography
              variant="body2"
              sx={{ mr: isMobile ? 0 : 3, mb: isMobile ? 1 : 0 }}
            >
              @mia_app
            </Typography>
          </Box>
          <Box display={"flex"}>
            <Box
              component="img"
              src={gmail}
              alt="Gmail"
              sx={{
                width: 18,
                height: 18,
                mr: 1,
                mb: isMobile ? 1 : 0,
              }}
            />
            <Typography
              variant="body2"
              sx={{ mr: isMobile ? 1 : 3, mb: isMobile ? 1 : 0 }}
            >
              mia_support@gmail.com
            </Typography>
          </Box>
          <Box display={"flex"}>
            <Box
              component="img"
              src={phone}
              alt="Phone"
              sx={{ width: 18, height: 18, mr: 1 }}
            />
            <Typography variant="body2">+1 (514) 619-9464</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutUs;
