import React from "react";
import "./ChatBot.css";
import video from "../../assets/video.mp4";
import animationData from "../../assets/robot-icon.json"; // Путь к вашей анимации Lottie
import { Box, IconButton, useMediaQuery } from "@mui/material";
import robot from "../../assets/robot.svg";
import send from "../../assets/send-icon.svg";

const ChatBot = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Box id="chatbot">
      {isMobile && (
        <Box
          height={"600px"}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginBottom: "400px",
          }}
        >
          <Box className="header">
            <img src={robot} alt="robot" className="avatar" />
            <span className="title">Мия Психолог</span>
          </Box>
          <Box className="message">
            <p style={{ paddingLeft: isMobile ? "30px" : "70px" }}>
              Привет! Это Мия,
              <br />Я помогу <a href="#">обрести гармонию</a>
            </p>
          </Box>
          <Box
            sx={{
              marginBottom: "10px",
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
              width: "100%",
            }}
          >
            <input
              type="text"
              placeholder="Как вам помочь?"
              inputMode="text" // добавлено для мобильных устройств
              style={{
                flex: 1,
                maxWidth: "100%",
                padding: "10px",
                border: "2px solid rgba(69, 167, 245, 1)",
                borderRadius: "16px",
                marginLeft: "20px",
              }}
            />
            <IconButton>
              <img src={send} alt="send-icon" width={40} />
            </IconButton>
          </Box>
        </Box>
      )}
      {!isMobile && (
        <Box className="video-background">
          <video autoPlay loop muted>
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <Box className="content">
            <Box className="header">
              <img src={robot} alt="robot" className="avatar" />
              <span className="title">Мия Психолог</span>
            </Box>
            <Box className="message">
              <p>
                Привет! Это Мия,
                <br />Я помогу <a href="#">обрести гармонию</a>
              </p>
            </Box>
            <Box className="input-box" sx={{ marginBottom: "10px" }}>
              <input
                type="text"
                placeholder="Как вам помочь?"
                inputMode="text"
              />
              <IconButton>
                <img src={send} alt="send-icon" width={40} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ChatBot;
