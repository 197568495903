import React from "react";
import Lottie from "react-lottie";
import animationData from "../../assets/robot-icon.json"; // Путь к вашей анимации Lottie
import CustomAppBar from "../AppBar/AppBar";
import { Box, useMediaQuery } from "@mui/material";
import background from "../../assets/background.png";
import backgroundForDesktop from "../../assets/backgroundForDesktop.png";

const Header = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleChatClick = () => {
    document.getElementById("chatbot")?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box
      sx={{
        position: "relative",
        height: "100vh",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <img
        src={isMobile ? background : backgroundForDesktop}
        alt="Background"
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: -1,
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CustomAppBar />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            color: "white",
            textAlign: "center",
            marginTop: { xs: "0", md: "-50px" },
            padding: { xs: "0 20px", md: "0" },
          }}
        >
          <Lottie
            options={defaultOptions}
            height={370}
            width={370}
            style={{ maxWidth: "100%", maxHeight: "100%", zIndex: "-1" }}
          />
          <Box
            sx={{
              marginTop: isMobile ? "-150px" : { xs: "-50px", md: "-100px" },
              paddingTop: { xs: "10px", md: "30px" },
            }}
          >
            <h1>
              Привет! Это Мия, <br /> Ваш карманный психолог
            </h1>
            <p>
              Мия – это чат-бот, созданный на основе искусственного <br />{" "}
              интеллекта, который заменяет реального психолога и <br />
              доступен в любое время и в любом месте.
            </p>
            <button
              onClick={handleChatClick}
              style={{
                backgroundColor: "#00B0FF",
                borderRadius: "50px",
                padding: "14px 34px 14px 34px",
                gap: "10px",
                color: "white",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "19.54px",
                marginTop: "15px",
                textTransform: "none",
                border: "none",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#008CBA",
                },
              }}
            >
              Чат с Мией
            </button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
