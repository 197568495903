import React from "react";
import { Box, Typography, Button, useMediaQuery } from "@mui/material";
import instagram from "../../assets/instagram.svg";
import gmail from "../../assets/gmail.svg";
import phone from "../../assets/phone.svg";
import { useNavigate, useLocation } from "react-router-dom";

const Footer = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();
  const location = useLocation();

  const handlePageClick = (page) => {
    let sectionId;

    if (page === "ТАРИФЫ") {
      sectionId = "Tariffs";
    } else if (page === "О НАС") {
      sectionId = "AboutUs";
    } else if (page === "ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ") {
      navigate("/privacy-policy");
      return;
    } else if (page === "Чат с Мией") {
      sectionId = "chatbot";
    } else {
      sectionId = "Mia";
    }

    if (location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        document
          .getElementById(sectionId)
          ?.scrollIntoView({ behavior: "smooth" });
      }, 300);
    } else {
      document
        .getElementById(sectionId)
        ?.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "300px",
        backgroundColor: "#0A0A23",
        color: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px 0",
        marginTop: "100px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: isMobile ? "start" : "center",
          width: "95%",
          mb: 2,
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <Typography
          sx={{ cursor: "pointer" }}
          onClick={() => handlePageClick("О МИЕ")}
        >
          О МИЕ
        </Typography>
        <Typography
          sx={{ cursor: "pointer" }}
          onClick={() => handlePageClick("ТАРИФЫ")}
        >
          ТАРИФЫ
        </Typography>
        <Typography
          sx={{ cursor: "pointer" }}
          onClick={() => handlePageClick("О НАС")}
        >
          О НАС
        </Typography>
        <Typography
          sx={{ cursor: "pointer" }}
          onClick={() => handlePageClick("ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ")}
        >
          ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ
        </Typography>
      </Box>
      <Box textAlign={"center"}>
        <Typography variant="h4" sx={{ mb: 1 }}>
          MIA
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          Ваш личный карманный психолог
        </Typography>
      </Box>

      <Box display={"flex"} justifyContent={"space-between"} width={"95%"}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: isMobile ? 0 : 2,
          }}
        >
          <Box
            component="img"
            src={instagram}
            alt="Instagram"
            sx={{ width: 24, height: 24, mr: 2 }}
          />
          <Box
            component="img"
            src={gmail}
            alt="Gmail"
            sx={{ width: 24, height: 24, mr: 2 }}
          />
          <Box
            component="img"
            src={phone}
            alt="Phone"
            sx={{ width: 24, height: 24 }}
          />
        </Box>
        {!isMobile && (
          <Typography variant="body2" sx={{ mb: 2, opacity: "50%" }}>
            © 2023 — Copyright
          </Typography>
        )}
        <Button
          variant="outlined"
          onClick={() => handlePageClick("Чат с Мией")}
          sx={{
            borderColor: "white",
            color: "white",
            borderRadius: "50px",
            textTransform: "none",
            padding: "14px, 34px, 14px, 34px",
            "&:hover": {
              borderColor: "white",
              backgroundColor: "rgba(255, 255, 255, 0.1)",
            },
          }}
        >
          Чат с Мией
        </Button>
      </Box>
      {isMobile && (
        <Box>
          <Typography variant="body2" sx={{ mb: 2, opacity: "50%" }}>
            © 2023 — Copyright
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Footer;
