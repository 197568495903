import React from "react";
import { Box, Typography, Grid, useMediaQuery } from "@mui/material";
import CardComponent from "./CardComponent";
import image1 from "../../assets/img (2).svg";
import image2 from "../../assets/img (3).svg";
import image3 from "../../assets/img (4).svg";
import image4 from "../../assets/img (5).svg";

const cardsData = [
  {
    image: image1,
    title: "Семейная терапия",
    description: "Наладить гармоничные отношения с близкими людьми",
    backgroundColor: "rgba(137, 197, 245, 1)",
  },
  {
    image: image2,
    title: "Принять себя",
    description: "Принять себя и повысить самооценку",
    backgroundColor: "rgba(196, 231, 192, 1)",
  },
  {
    image: image3,
    title: "Пережить перемены",
    description: "Пережить переезд, развод, потерю работы",
    backgroundColor: "rgba(248, 215, 229, 1)",
  },
  {
    image: image4,
    title: "Справиться со стрессом",
    description: "Справляться со стрессом, тревогой и страхами",
    backgroundColor: "rgba(193, 222, 223, 1)",
  },
];

const AboutMia = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <Box
      id="AboutMia"
      sx={{
        textAlign: "center",
        padding: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: isMobile ? "50px" : "150px",
        marginBottom: "70px",
      }}
    >
      {isMobile && (
        <Typography
          variant="h4"
          sx={{ marginTop: 4, marginBottom: 4, fontWeight: 700 }}
        >
          С чем <br /> поможет Мия?
        </Typography>
      )}
      <Grid
        container
        spacing={4}
        justifyContent={isMobile ? "center" : "flex-end"}
        alignItems="center"
        marginRight={isMobile ? 0 : 30}
        flexDirection={isMobile ? "column" : "row"}
      >
        <Grid item zIndex={2} marginBottom={isMobile ? 4 : 20}>
          <CardComponent
            image={cardsData[0].image}
            title={cardsData[0].title}
            description={cardsData[0].description}
            backgroundColor={cardsData[0].backgroundColor}
          />
        </Grid>
        <Grid
          item
          marginTop={isMobile ? 0 : 17}
          marginLeft={isMobile ? 0 : -15}
          marginBottom={isMobile ? 7 : 0}
        >
          <CardComponent
            image={cardsData[1].image}
            title={cardsData[1].title}
            description={cardsData[1].description}
            backgroundColor={cardsData[1].backgroundColor}
          />
        </Grid>
      </Grid>

      {!isMobile && (
        <Typography
          variant="h4"
          sx={{ marginTop: 4, marginBottom: 4, fontWeight: 700 }}
        >
          С чем <br /> поможет Мия?
        </Typography>
      )}

      <Grid
        container
        spacing={4}
        justifyContent={isMobile ? "center" : "start"}
        paddingLeft={isMobile ? 0 : 15}
        flexDirection={isMobile ? "column" : "row"}
      >
        <Grid item zIndex={2} marginBottom={isMobile ? 4 : 0}>
          <CardComponent
            image={cardsData[2].image}
            title={cardsData[2].title}
            description={cardsData[2].description}
            backgroundColor={cardsData[2].backgroundColor}
          />
        </Grid>
        <Grid
          item
          marginTop={isMobile ? 0 : 17}
          marginLeft={isMobile ? 0 : -10}
        >
          <CardComponent
            image={cardsData[3].image}
            title={cardsData[3].title}
            description={cardsData[3].description}
            backgroundColor={cardsData[3].backgroundColor}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutMia;
