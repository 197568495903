import React from "react";
import { Box, Typography, Grid, useMediaQuery } from "@mui/material";
import "./Mia.css";
import Lottie from "react-lottie";
import animationData from "../../assets/robot-icon.json";

const Mia = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const features = [
    {
      title: "Конфиденциальность",
      description:
        "Обеспечивает конфиденциальность пользователей и безопасность данных.",
    },
    {
      title: "Поддержка",
      description:
        "Ваша консультация будет основываться на вашем личном опыте и целях.",
    },
    {
      title: "Надежность",
      description:
        "Последовательная и надежная поддержка психического здоровья, доступна 24/7.",
    },
    {
      title: "Доказанные результаты",
      description:
        "Подкреплено научно обоснованными методами КГП и положительными отзывами пользователей.",
    },
    {
      title: "Доступность",
      description: "Экономичное решение по сравнению с традиционной терапией.",
    },
    {
      title: "Безоценочный подход",
      description:
        "Обеспечивает безопасную и благоприятную среду без каких-либо суждений.",
    },
  ];

  return (
    <Box
      id="Mia"
      className="about-mia"
      sx={{ padding: "20px", textAlign: "center" }}
    >
      <Typography
        textAlign={"center"}
        variant="h4"
        gutterBottom
        sx={{
          padding: isMobile ? "0" : "0 350px",
          fontWeight: 700,
          color: "rgba(69, 167, 245, 1)",
          marginTop: "30px",
        }}
      >
        MIA - это чатбот, созданный на основе искусственного интеллекта и
        когнитивно-бихевиоральной терапии (КГП), который имитирует человеческое
        общение.
      </Typography>
      <Grid
        container
        spacing={2}
        justifyContent="space-evenly"
        sx={{ padding: isMobile ? "0" : "0 80px" }}
        marginTop={5}
        flexDirection={isMobile ? "column" : "row"}
      >
        <Grid item xs={12} md={4}>
          <Lottie options={defaultOptions} height={370} width={370} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            {features.map((feature, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Box
                  className="feature-box"
                  sx={{
                    padding: "10px",
                    textAlign: "start",
                    borderRadius: "8px",
                  }}
                >
                  <Typography variant="h6" className="feature-title">
                    {feature.title}
                  </Typography>
                  <Typography
                    sx={{
                      width: isMobile ? "100%" : "70%",
                      color: "rgba(20, 20, 20, 1)",
                      fontWeight: 400,
                      fontSize: "16px",
                    }}
                  >
                    {feature.description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Mia;
