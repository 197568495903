import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import Header from "../Components/Header/Header";
import AboutMia from "../Components/AboutMia/AboutMia";
import ChatBot from "../Components/ChatBot/ChatBot";
import PricingPlans from "../Components/PricingPlans/PricingPlans";
import LifeImprovements from "../Components/LifeImprovements/LifeImprovements";
import Mia from "../Components/Mia/Mia";
import AboutUs from "../Components/AboutUs/AboutUs";
import Footer from "../Components/Footer/Footer";

const MainLayout = (props) => {
  return (
    <Box>
      <Header />
      <AboutMia />
      <ChatBot />
      <PricingPlans />
      <LifeImprovements />
      <Mia />
      <AboutUs />
      <Footer />
    </Box>
  );
};

MainLayout.propTypes = {};

export default MainLayout;
